<template>
  <div
    id="main-view"
    :class="['move-'+transitionDirection, {'without-navigation': !bottomNavigationIsVisible}]"
  >
    <div class="body">
      <transition
        name="slide"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @after-leave="afterLeave"
      >
        <router-view :key="$route.params.nodeId"></router-view>
      </transition>
    </div>

    <div class="bottom-navigation">
      <router-link
        v-for="item in navigationItems"
        :to="item.to"
        :key="item.to"
      >
        <mu-icon :value="item.icon"></mu-icon>
        <label>{{ $t(item.label) }}</label>
      </router-link>
    </div>
  </div>
</template>

<script>
var incomingCover = null;
var outgoingCover = null;

export default {
  name: "deck",

  data() {
    return {
      transitionDirection: "left",
      type:1,
      isAllowed: {
        classroom: false,
        teaching: false,
        billing: false,
        compose: false,
        transport: false,
        geoFeed: false
      }
    };
  },

  mounted() {

    this.$store.state.api.get(`people/${this.$store.state.user.id}/getType`)
		.then(data => { this.type = data.type});

  },

  created() {
    let allowedUrls = {
      classroom: `people/${this.$store.state.user.id}/classroom`,
      teaching: `people/${this.$store.state.user.id}/teaching`,
      billing: `people/${this.$store.state.user.id}/settings/modules/billing`,
      compose: `people/${this.$store.state.user.id}/posts/sent`,
      transport: `people/${this.$store.state.user.id}/transport`,
      geoFeed: `people/${this.$store.state.user.id}/geo/feed`
    };

    let storedData = JSON.parse(localStorage.getItem("phidias.deck.cache"));
    if (storedData) {
      this.isAllowed = storedData;
    }

    this.$store.state.api.allowed(Object.values(allowedUrls)).then(response => {
      for (var i in allowedUrls) {
        this.isAllowed[i] = response[allowedUrls[i]];
      }

      localStorage.setItem(
        "phidias.deck.cache",
        JSON.stringify(this.isAllowed)
      );
    });
  },

  computed: {
    navigationItems() {

        var deckNavigation = [
            {
                to: "/highlights",
                icon: "dashboard",
                label: "nav.highlights"
            },
            {
                to: "/inbox",
                icon: "inbox",
                label: "nav.inbox"
            },
            {
                to: "/test/calendar",
                icon: "event",
                label: "nav.calendar"
            },
            {
                to: "/launcher",
                icon: "menu",
                label: "nav.school"
            }
        ];
       
        if(this.isAllowed.classroom) {
            if (this.type == 3)
            {   
                deckNavigation =  
                [
                    {
                        to: "/highlights",
                        icon: "dashboard",
                        label: "nav.highlights"
                    },

                    {
                        to: "/inbox",
                        icon: "inbox",
                        label: "nav.inbox"
                    },

                    {
                        to: "/classroom",
                        icon: "class",
                        label: "launcher.classroom"
                    },

                    {
                        to: "/test/calendar",
                        icon: "event",
                        label: "nav.calendar"
                    },

                    {
                        to: "/launcher",
                        icon: "menu",
                        label: "nav.school"
                    }
                ];
            }
            else if (this.type == 1)
            {
                deckNavigation = 
                [
                    {
                        to: "/highlights",
                        icon: "dashboard",
                        label: "nav.highlights"
                    },

                    {
                        to: "/inbox",
                        icon: "inbox",
                        label: "nav.inbox"
                    },

                    {
                        to: "/people/"+this.$store.state.user.id+"/classroom",
                        icon: "class",
                        label: "launcher.classroom"
                    },

                    {
                        to: "/test/calendar",
                        icon: "event",
                        label: "nav.calendar"
                    },

                    {
                        to: "/launcher",
                        icon: "menu",
                        label: "nav.school"
                    }
                ];
            }
        }
        
        return deckNavigation;
    },

    user() {
        return this.$store.state.user;
    },

    bottomNavigationIsVisible() {
      for (let i = 0; i < this.navigationItems.length; i++) {
        if (this.$route.path == this.navigationItems[i].to) {
          return true;
        }
      }
      return false;
    }
  },

  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.push("login");
    },

    beforeEnter(el) {
      incomingCover = el.querySelector(".phi-page-header");
    },

    beforeLeave(el) {
      outgoingCover = el.querySelector(".phi-page-header");

      setTimeout(() => {
        incomingCover &&
          (incomingCover.initialHeight = incomingCover.clientHeight);

        var newCoverHeight = outgoingCover ? outgoingCover.clientHeight : 0;

        incomingCover && (incomingCover.style.overflow = "hidden");
        outgoingCover && (outgoingCover.style.overflow = "hidden");

        incomingCover && (incomingCover.style.height = newCoverHeight + "px");
        incomingCover &&
          (incomingCover.style.minHeight = newCoverHeight + "px");

        outgoingCover && (outgoingCover.style.height = newCoverHeight + "px");
        outgoingCover &&
          (outgoingCover.style.minHeight = newCoverHeight + "px");

        if (incomingCover && outgoingCover) {
          incomingCover.initialbackgroundColor = window.getComputedStyle(
            incomingCover
          ).backgroundColor;
          incomingCover.style.backgroundColor = window.getComputedStyle(
            outgoingCover
          ).backgroundColor;
        }

        // force repaint
        incomingCover && incomingCover.offsetHeight;
        outgoingCover && outgoingCover.offsetHeight;
      }, 0);
    },

    enter(el) {
      setTimeout(() => {
        var newCoverHeight = incomingCover ? incomingCover.initialHeight : 0;
        incomingCover && (incomingCover.style.height = newCoverHeight + "px");
        incomingCover &&
          (incomingCover.style.minHeight = newCoverHeight + "px");

        outgoingCover && (outgoingCover.style.height = newCoverHeight + "px");
        outgoingCover &&
          (outgoingCover.style.minHeight = newCoverHeight + "px");

        incomingCover &&
          (incomingCover.style.backgroundColor =
            incomingCover.initialbackgroundColor);
      }, 0);
    },

    afterLeave(el) {
      incomingCover && (incomingCover.style.height = "auto");
      incomingCover && (incomingCover.style.minHeight = "auto");
      incomingCover && (incomingCover.style.overflow = "initial");

      outgoingCover && (outgoingCover.style.height = "auto");
      outgoingCover && (outgoingCover.style.minHeight = "auto");
      outgoingCover && (outgoingCover.style.overflow = "initial");

      this.$emit("transitionFinished", true);
    }
  },

  watch: {
    $route(to, from) {
      if (to.params.nodeId) {
        this.transitionDirection =
          from.params.nodeId < to.params.nodeId ? "left" : "right";
      } else {
        let diff = to.meta.order - from.meta.order;
        this.transitionDirection =
          diff == 0 ? "fade" : diff > 0 ? "left" : "right";
      }
    }
  }
};
</script>


<style lang="scss">
$bottom-navigation-height: 64px;
$bottom-navigation-transition-duration: 0.28s;

#main-view {
  height: 100%;

  & > .body {
    height: 100%;
    padding-bottom: $bottom-navigation-height;
    transition: padding-bottom $bottom-navigation-transition-duration;
  }

  & > .bottom-navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    height: $bottom-navigation-height;
    display: flex;

    background-color: #fff;
    -webkit-box-shadow: 0px -1px 7px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px -1px 7px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px -1px 7px 0px rgba(0, 0, 0, 0.1);

    transition: bottom $bottom-navigation-transition-duration;

    & > * {
      flex: 1;
      text-align: center;
      padding: 12px;

      .mu-icon {
        display: block;
        position: relative;
        top: 5px;

        color: #7e848c;
        font-size: 24px;

        transition: top 200ms, font-size 200ms;
      }

      label {
        display: block;
        position: relative;
        top: 6px;

        font-size: 10px;
        color: #7e848c;

        opacity: 0.7;
        transition: all 200ms;
      }

      &.router-link-active {
        label {
          top: 3px;
          opacity: 1;
          // font-size: 11px;
          color: #1c89b8;
        }

        .mu-icon {
          color: #1c89b8;
          top: 0;
          font-size: 26px;
        }
      }
    }
  }

  &.without-navigation {
    .body {
      padding-bottom: 0;
    }

    .bottom-navigation {
      bottom: -$bottom-navigation-height;
    }
  }
}

$transition-duration: 0.28s;
$transition-displacement: 70px;

.slide-enter-active,
.slide-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  transition: opacity $transition-duration;

  .phi-page-toolbar {
    transition: transform $transition-duration;
  }

  .phi-page-header {
    transition: $transition-duration;
  }

  .phi-page-contents > * {
    transition: transform $transition-duration;
  }
}

.slide-leave-active {
  opacity: 0;
}

.slide-enter,
.slide-enter-active {
  z-index: 1;
}

.slide-leave,
.slide-leave-active {
  z-index: 2;
}

.slide-enter {
  .phi-page-toolbar {
    transform: translate3d(0, -100%, 0);
  }
}

.slide-leave-active {
  .phi-page-toolbar {
    transform: translate3d(0, -100%, 0);
  }
}

.move-fade {
  .slide-enter {
    .phi-page-contents > *,
    .phi-page-header {
      transform: translate3d(0, 20px, 0);
    }
  }

  .slide-leave-active {
    .phi-page-contents > *,
    .phi-page-header {
      transform: translate3d(0, 20px, 0);
    }
  }
}

.move-left {
  .slide-enter {
    .phi-page-contents > *,
    .phi-page-header {
      transform: translate3d($transition-displacement, 0, 0);
    }
  }

  .slide-leave-active {
    .phi-page-contents > *,
    .phi-page-header {
      transform: translate3d(-$transition-displacement, 0, 0);
    }
  }
}

.move-right {
  .slide-enter {
    .phi-page-contents > *,
    .phi-page-header {
      transform: translate3d(-$transition-displacement, 0, 0);
    }
  }

  .slide-leave-active {
    .phi-page-contents > *,
    .phi-page-header {
      transform: translate3d($transition-displacement, 0, 0);
    }
  }
}
</style>